import React from "react"
import { Link, StaticQuery, graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import styled from "styled-components"
import { GlobalCard } from "./globalCard"

const WholeCardContainer = styled.div`
  display: grid;
  grid-template-columns: auto;
  grid-row-gap: 40px;
  background-color: rgba(47, 57, 78, 0.1);
  padding: 40px 0;
  font-family: "Montserrat", sans-serif;
`
const CardTitleContainer = styled.div`
  display: grid;
  grid-template-columns: 76%;
  justify-content: center;
  color: #2f394e;
`
const CardContainerTitle = styled.h2`
  font-size: 32px;
  @media (max-width: 575px) {
    font-size: 23px;
  }
`
const CardContainerText = styled.p`
  font-size: 18px;
  color: #3d4d69;
  @media (max-width: 575px) {
    font-size: 14px;
  }
`

const StyledCardContainer = styled.div`
  display: grid;
  grid-template-columns: 17% 17% 17% 17%;
  justify-content: center;
  align-content: center;
  grid-gap: 40px;

  @media (max-width: 1200px) {
    grid-template-columns: 30% 30%;
    align-content: space-evenly;
    grid-gap: 40px 40px;
  }
  @media (max-width: 767px) {
    grid-template-columns: 35% 35%;
    align-content: space-evenly;
    grid-gap: 40px 40px;
  }
  @media (max-width: 575px) {
    grid-template-columns: 60%;
    align-content: space-evenly;
    grid-gap: 40px 40px;
  }
`

export const PopularIntegrations = ({ data }) => (
  <WholeCardContainer>
    <CardTitleContainer>
      <CardContainerTitle>Most Popular Integrations</CardContainerTitle>
      <CardContainerText>
        People love to integrate tools with Groupboss & more are coming soon.
      </CardContainerText>
    </CardTitleContainer>
    <StyledCardContainer>
      <GlobalCard
        imageHref="https://groupboss.io/help/integrate-groupboss-with-mailchimp/"
        cardImage={
          <GatsbyImage
            image={data.auto_res_mailchimp.childImageSharp.gatsbyImageData}
          />
        }
        cardTitle="mailchimp"
        cardText="Do big things with the right tool and guidance"
        cardHref="https://groupboss.io/help/integrate-groupboss-with-mailchimp/"
        cardA="Learn more..."
      />
      <GlobalCard
        imageHref="https://groupboss.io/help/integrate-groupboss-with-activecampaign/"
        cardImage={
          <GatsbyImage
            image={data.auto_res_2.childImageSharp.gatsbyImageData}
          />
        }
        cardTitle="ActiveCampaign"
        cardText="Create a better customer experience with automation"
        cardHref="https://groupboss.io/help/integrate-groupboss-with-activecampaign/"
        cardA="Learn more..."
      />
      <GlobalCard
        imageHref="https://groupboss.io/help/integrate-groupboss-with-lemlist/"
        cardImage={
          <GatsbyImage
            image={data.auto_res_3.childImageSharp.gatsbyImageData}
          />
        }
        cardTitle="lemlist"
        cardText="Get more replies to your cold emails and boost sales"
        cardHref="https://groupboss.io/help/integrate-groupboss-with-lemlist/"
        cardA="Learn more..."
      />
      <GlobalCard
        imageHref="https://groupboss.io/help/integrate-groupboss-with-snovio/"
        cardImage={
          <GatsbyImage
            image={data.auto_res_4.childImageSharp.gatsbyImageData}
          />
        }
        cardTitle="Snovio"
        cardText="Get more leads and convert them faster to increase sales"
        cardHref="https://groupboss.io/help/integrate-groupboss-with-snovio/"
        cardA="Learn more..."
      />
      <GlobalCard
        imageHref="https://groupboss.io/help/integrate-groupboss-with-convertkit/"
        cardImage={
          <GatsbyImage
            image={data.auto_res_5.childImageSharp.gatsbyImageData}
          />
        }
        cardTitle="ConverKit"
        cardText="Find and connect with your true fans faster with Convertkit"
        cardHref="https://groupboss.io/help/integrate-groupboss-with-convertkit/"
        cardA="Learn more..."
      />
      <GlobalCard
        imageHref="https://groupboss.io/help/integrate-groupboss-with-getresponse/"
        cardImage={
          <GatsbyImage
            image={data.auto_res_6.childImageSharp.gatsbyImageData}
          />
        }
        cardTitle="GetResponse"
        cardText="Automate your marketing by sending emails with GetResponse"
        cardHref="https://groupboss.io/help/integrate-groupboss-with-getresponse/"
        cardA="Learn more..."
      />
      <GlobalCard
        imageHref="https://groupboss.io/help/integrate-groupboss-with-moosend/"
        cardImage={
          <GatsbyImage image={data.moosend.childImageSharp.gatsbyImageData} />
        }
        cardTitle="Moosend"
        cardText="All in one email marketing platform for your business"
        cardHref="https://groupboss.io/help/integrate-groupboss-with-moosend/"
        cardA="Learn more..."
      />
      <GlobalCard
        imageHref="https://groupboss.io/help/integrate-groupboss-with-sendfox/"
        cardImage={
          <GatsbyImage image={data.sendfox.childImageSharp.gatsbyImageData} />
        }
        cardTitle="SendFox"
        cardText="Send newsletters to your audiences with the most affordable tool"
        cardHref="https://groupboss.io/help/integrate-groupboss-with-sendfox/"
        cardA="Learn more..."
      />
      <GlobalCard
        imageHref="https://groupboss.io/help/integrate-groupboss-with-sendinblue/"
        cardImage={
          <GatsbyImage
            image={data.sendinblue.childImageSharp.gatsbyImageData}
          />
        }
        cardTitle="Sendinblue"
        cardText="Grow your business with the smartest platform"
        cardHref="https://groupboss.io/help/integrate-groupboss-with-sendinblue/"
        cardA="Learn more..."
      />
      <GlobalCard
        imageHref="https://groupboss.io/help/integrate-groupboss-with-sendgrid/"
        cardImage={
          <GatsbyImage image={data.sendgrid.childImageSharp.gatsbyImageData} />
        }
        cardTitle="Sendgrid"
        cardText="Send emails with confidence and get more conversions"
        cardHref="https://groupboss.io/help/integrate-groupboss-with-sendgrid/"
        cardA="Learn more..."
      />
      <GlobalCard
        imageHref="https://groupboss.io/help/integrate-groupboss-with-klaviyo/"
        cardImage={
          <GatsbyImage image={data.klaviyo.childImageSharp.gatsbyImageData} />
        }
        cardTitle="Klaviyo"
        cardText="Best marketing tool for the businesses that sell online"
        cardHref="https://groupboss.io/help/integrate-groupboss-with-klaviyo/"
        cardA="Learn more..."
      />

      <GlobalCard
        imageHref="https://groupboss.io/help/integrate-groupboss-with-mailerlite/"
        cardImage={
          <GatsbyImage
            image={data.mailerlite.childImageSharp.gatsbyImageData}
          />
        }
        cardTitle="Mailerlite"
        cardText="Do email marketing in your way and become an expert"
        cardHref="https://groupboss.io/help/integrate-groupboss-with-mailerlite/"
        cardA="Learn more..."
      />
      <GlobalCard
        imageHref="https://groupboss.io/help/integrate-groupboss-with-gohighlevel/"
        cardImage={
          <GatsbyImage
            image={data.goHighLevel.childImageSharp.gatsbyImageData}
          />
        }
        cardTitle="GoHighLevel"
        cardText="The best platform to take your sales and marketing strategies to the next level."
        cardHref="https://groupboss.io/help/integrate-groupboss-with-gohighlevel/"
        cardA="Learn more..."
      />
      <GlobalCard
        imageHref="https://groupboss.io/help/integrate-groupboss-with-drip/"
        cardImage={
          <GatsbyImage image={data.Drip.childImageSharp.gatsbyImageData} />
        }
        cardTitle="Drip"
        cardText="Generate leads for your e-commerce business from Facebook groups."
        cardHref="https://groupboss.io/help/integrate-groupboss-with-drip/"
        cardA="Learn more..."
      />
      <GlobalCard
        imageHref="https://groupboss.io/help/integrate-groupboss-with-gist/"
        cardImage={
          <GatsbyImage image={data.Gist.childImageSharp.gatsbyImageData} />
        }
        cardTitle="Gist"
        cardText="CRM software that engages consumers in tailored conversations to foster trust and boost sales."
        cardHref="https://groupboss.io/help/integrate-groupboss-with-gist/"
        cardA="Learn more..."
      />
      <GlobalCard
        imageHref="https://groupboss.io/help/integrate-groupboss-with-hyros/"
        cardImage={
          <GatsbyImage image={data.Hyros.childImageSharp.gatsbyImageData} />
        }
        cardTitle="Hyros"
        cardText="Take your leads from Facebook groups to Hyros for better tracking and campaign.        "
        cardHref="https://groupboss.io/help/integrate-groupboss-with-hyros/"
        cardA="Learn more..."
      />
      <GlobalCard
        imageHref="https://groupboss.io/help/integrate-groupboss-with-pabbly/"
        cardImage={
          <GatsbyImage image={data.Pabbly.childImageSharp.gatsbyImageData} />
        }
        cardTitle="Pabbly"
        cardText="Email marketing software to increase revenue rather than expenses."
        cardHref="https://groupboss.io/help/integrate-groupboss-with-pabbly/"
        cardA="Learn more..."
      />
      
      <GlobalCard
      imageHref="https://groupboss.io/help/omnisend-and-groupboss-integration/"
      cardImage={
        <GatsbyImage image={data.Omnisend.childImageSharp.gatsbyImageData} />
      }
      cardTitle="Omnisend"
      cardText="An outstanding email marketing software for your e-commerce store."
      cardHref="https://groupboss.io/help/omnisend-and-groupboss-integration/"
      cardA="Learn more..."
    />
    <GlobalCard
      imageHref="https://groupboss.io/help/emailoctopus-groupboss-integration/"
      cardImage={
        <GatsbyImage image={data.EmailOctopus.childImageSharp.gatsbyImageData} />
      }
      cardTitle="EmailOctopus"
      cardText="Email marketing is easier now with EmailOctopus"
      cardHref="https://groupboss.io/help/emailoctopus-groupboss-integration/"
      cardA="Learn more..."
    />
    <GlobalCard
      imageHref="https://groupboss.io/help/groupboss-benchmark-email-integration/"
      cardImage={
        <GatsbyImage image={data.BenchmarkEmail.childImageSharp.gatsbyImageData} />
      }
      cardTitle="Benchmark Email"
      cardText="A powerful and simple email marketing software"
      cardHref="https://groupboss.io/help/groupboss-benchmark-email-integration/"
      cardA="Learn more..."
    />
    
    <GlobalCard
      imageHref="https://groupboss.io/help/clickfunnels-and-groupboss-integration/"
      cardImage={
        <GatsbyImage image={data.ClickFunnels.childImageSharp.gatsbyImageData} />
      }
      cardTitle="ClickFunnels"
      cardText="Easily design and send emails with ClickFunnels"
      cardHref="https://groupboss.io/help/clickfunnels-and-groupboss-integration/"
      cardA="Learn more..."
    />

<GlobalCard
      imageHref="https://groupboss.io/help/groupboss-and-platformly-integration/"
      cardImage={
        <GatsbyImage image={data.Platformly.childImageSharp.gatsbyImageData} />
      }
      cardTitle="Platformly"
      cardText="Automate your business with Platformly"
      cardHref="https://groupboss.io/help/groupboss-and-platformly-integration/"
      cardA="Learn more..."
    />
    <GlobalCard
      imageHref="https://groupboss.io/help/how-to-integrate-groupboss-with-systeme-io/"
      cardImage={
        <GatsbyImage image={data.Systeme.childImageSharp.gatsbyImageData} />
      }
      cardTitle="Systeme.io"
      cardText="Launch and run your business with Systeme.io"
      cardHref="https://groupboss.io/help/how-to-integrate-groupboss-with-systeme-io/"
      cardA="Learn more..."
    />
     <GlobalCard
      imageHref="https://groupboss.io/help/hubspot-integration-with-groupboss/"
      cardImage={
        <GatsbyImage image={data.HubSpot.childImageSharp.gatsbyImageData} />
      }
      cardTitle="HubSpot"
      cardText="A great CRM to control your online activities"
      cardHref="https://groupboss.io/help/hubspot-integration-with-groupboss/"
      cardA="Learn more..."
    />

     <GlobalCard
      imageHref="https://groupboss.io/help/airtable-integration-with-groupboss/"
      cardImage={
        <GatsbyImage image={data.Airtable.childImageSharp.gatsbyImageData} />
      }
      cardTitle="Airtable"
      cardText="Save your leads from Facebook group Airtable easily"
      cardHref="https://groupboss.io/help/airtable-integration-with-groupboss/"
      cardA="Learn more..."
    />

    </StyledCardContainer>
  </WholeCardContainer>
)

export default props => (
  <StaticQuery
    query={graphql`
      {
        auto_res_mailchimp: file(
          relativePath: { eq: "auto_res_mailchimp.png" }
        ) {
          childImageSharp {
            gatsbyImageData(quality: 100, layout: FULL_WIDTH)
          }
        }
        auto_res_2: file(relativePath: { eq: "auto_res_2.png" }) {
          childImageSharp {
            gatsbyImageData(quality: 100, layout: FULL_WIDTH)
          }
        }
        auto_res_3: file(relativePath: { eq: "auto_res_3.png" }) {
          childImageSharp {
            gatsbyImageData(quality: 100, layout: FULL_WIDTH)
          }
        }
        auto_res_4: file(relativePath: { eq: "auto_res_4.png" }) {
          childImageSharp {
            gatsbyImageData(quality: 100, layout: FULL_WIDTH)
          }
        }
        auto_res_5: file(relativePath: { eq: "auto_res_5.png" }) {
          childImageSharp {
            gatsbyImageData(quality: 100, layout: FULL_WIDTH)
          }
        }
        auto_res_6: file(relativePath: { eq: "auto_res_6.png" }) {
          childImageSharp {
            gatsbyImageData(quality: 100, layout: FULL_WIDTH)
          }
        }
        moosend: file(relativePath: { eq: "moosend.png" }) {
          childImageSharp {
            gatsbyImageData(quality: 100, layout: FULL_WIDTH)
          }
        }
        sendfox: file(relativePath: { eq: "sendfox.png" }) {
          childImageSharp {
            gatsbyImageData(quality: 100, layout: FULL_WIDTH)
          }
        }
        automizy: file(relativePath: { eq: "auto_res_automizy.png" }) {
          childImageSharp {
            gatsbyImageData(quality: 100, layout: FULL_WIDTH)
          }
        }
        klaviyo: file(relativePath: { eq: "auto_res_klaviyo.png" }) {
          childImageSharp {
            gatsbyImageData(quality: 100, layout: FULL_WIDTH)
          }
        }
        mailerlite: file(relativePath: { eq: "auto_res_mailerlite.png" }) {
          childImageSharp {
            gatsbyImageData(quality: 100, layout: FULL_WIDTH)
          }
        }
        sendgrid: file(relativePath: { eq: "auto_res_sendgrid.png" }) {
          childImageSharp {
            gatsbyImageData(quality: 100, layout: FULL_WIDTH)
          }
        }
        sendinblue: file(relativePath: { eq: "auto_res_sendinblue.png" }) {
          childImageSharp {
            gatsbyImageData(quality: 100, layout: FULL_WIDTH)
          }
        }
        goHighLevel: file(relativePath: { eq: "highlevel.png" }) {
          childImageSharp {
            gatsbyImageData(quality: 100, layout: FULL_WIDTH)
          }
        }
        Drip: file(relativePath: { eq: "drip.png" }) {
          childImageSharp {
            gatsbyImageData(quality: 100, layout: FULL_WIDTH)
          }
        }
        Gist: file(relativePath: { eq: "gist.png" }) {
          childImageSharp {
            gatsbyImageData(quality: 100, layout: FULL_WIDTH)
          }
        }
        Hyros: file(relativePath: { eq: "hyros.png" }) {
          childImageSharp {
            gatsbyImageData(quality: 100, layout: FULL_WIDTH)
          }
        }
        Pabbly: file(relativePath: { eq: "pabbly.png" }) {
          childImageSharp {
            gatsbyImageData(quality: 100, layout: FULL_WIDTH)
          }
        }
        Omnisend: file(relativePath: { eq: "Omnisend.png" }) {
          childImageSharp {
            gatsbyImageData(quality: 100, layout: FULL_WIDTH)
          }
        }
        EmailOctopus: file(relativePath: { eq: "EmailOctopus.png" }) {
          childImageSharp {
            gatsbyImageData(quality: 100, layout: FULL_WIDTH)
          }
        }
        BenchmarkEmail: file(relativePath: { eq: "BenchmarkEmail.png" }) {
          childImageSharp {
            gatsbyImageData(quality: 100, layout: FULL_WIDTH)
          }
        }
        Platformly: file(relativePath: { eq: "Platformly.png" }) {
          childImageSharp {
            gatsbyImageData(quality: 100, layout: FULL_WIDTH)
          }
        }
        ClickFunnels: file(relativePath: { eq: "ClickFunnels.png" }) {
          childImageSharp {
            gatsbyImageData(quality: 100, layout: FULL_WIDTH)
          }
        }
        Systeme: file(relativePath: { eq: "Systeme.jpeg" }) {
          childImageSharp {
            gatsbyImageData(quality: 100, layout: FULL_WIDTH)
          }
        }
        HubSpot: file(relativePath: { eq: "HubSpot.png" }) {
          childImageSharp {
            gatsbyImageData(quality: 100, layout: FULL_WIDTH)
          }
        }
        Airtable: file(relativePath: { eq: "Airtable.png" }) {
          childImageSharp {
            gatsbyImageData(quality: 100, layout: FULL_WIDTH)
          }
        }

      }
    `}
    render={data => <PopularIntegrations data={data} {...props} />}
  />
)
